import {React,useContext, useEffect} from 'react'
import {userContext} from '../Context/Context'
function BlogSingle() {
  const {singleBlog,setSingleBlog}=useContext(userContext)

  useEffect(()=>{
    document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  },[])
  return (
    <div className='container mt-5 mb-5'>
        <div className="row">
            <div className="col-md-6">
                <img src={singleBlog.image} alt="" style={{width:'inherit',height:'inherit'}}/>
            </div>
            <div className="col-md-6">
                <h1>{singleBlog.Name}</h1>
                <p>{singleBlog.Description}</p>
            </div>
        </div>
    </div>
      //   <div className="container mt-5 mb-5">
      //   <div className="row">
      //     <div className="col-md-6">
      //       <img
      //         src="http://res.cloudinary.com/dbpxhm5vt/image/upload/v1669724296/Nediyath%20Product%20image/x1d57qjzqgxqb26cvogy.png"
      //         alt=""
      //         style={{ width: "inherit", height: "inherit" }}
      //       />
      //     </div>
      //     <div className="col-md-6">
      //       <h1>
      //         Lorem Ipsum is simply dummy text of the printing and typesetting
      //         industry.
      //       </h1>
      //       <p>
      //         Lorem Ipsum has been the industry's standard dummy text ever since
      //         the 1500s, when an unknown printer took a galley of type and
      //         scrambled it to make a type specimen book. It has survived not only
      //         five centuries, but also the leap into electronic typesetting,
      //         remaining essentially unchanged. It was popularised in the 1960s
      //         with the release of Letraset sheets containing Lorem Ipsum passages,
      //         and more recently with desktop publishing software like Aldus
      //         PageMaker including versions of Lorem Ipsum.
      //       </p>
      //     </div>
      //   </div>
      // </div>
  )
}

export default BlogSingle