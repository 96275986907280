import React from 'react'
import Gallery from '../Components/Gallery/Gallery'

function GalleryPage() {
  return (
    <div>
        <Gallery></Gallery>
    </div>
  )
}

export default GalleryPage