import React from "react";
import Footer from "../Footer/Footer";
import SubHeader from "../Header/SubHeader";

export default function ContactPage() {
  return (
    <div>
      <SubHeader />
      <section class="space">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="news-img-block">
                <img
                  src="images/play-img.png"
                  class="img-fluid w-100"
                  alt="#"
                />
                <a
                  class="video-play-button popup-youtube"
                  href="https://www.youtube.com/watch?v=S7qO_9-NJmA"
                >
                  <span></span>
                </a>
                <div id="video-overlay" class="video-overlay">
                  <a class="video-overlay-close">&times;</a>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="video-play-text" style={{ textAlign: "justify" }}>
                <h2>General Practitioner</h2>
                <p>
                  At <b>The Aroor Clinic</b>, we provide efficient treatment following the
                  best-suited opinions from our medical professionals. The
                  clinic's medicine department is a crucial and fundamental
                  component. It places a focus on promoting health and
                  preventing diseases. Patients usually go to a general
                  practitioner initially before being sent to their respective
                  specialties. Our patients will receive high-quality care and
                  be at ease.
                </p>
                <p>
                  Our doctor prioritizes treating the patient as a whole, rather
                  than just the specific condition. We also assist patients in
                  understanding their illnesses and creating preventative plans
                  while they are in the hospital receiving the best healthcare
                  available.
                </p>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
