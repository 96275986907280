import React from "react";
import Footer from "../Footer/Footer";
import SubHeader from "../Header/SubHeader";

export default function ContactPage() {
  return (
    <div>
      <SubHeader></SubHeader>
      <section class="space sub-header">
        <div class="container container-custom">
          <div class=" row">
            <div class="col-md-6">
              <div class="sub-header_content">
                <p>CONTACT US</p>
                <br></br>
                <span>
                  <i>Home / Contact Us</i>
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sub-header_main">
                <h2>Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="space">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-5">
              <div class="contact-box">
                {/* <p>Lorem ipsum dolor sit amet consect etur adipi sicing elit, sed do eiusm</p> */}
                <hr />
                <div class="contact-title">
                  <h4>Contact Information</h4>
                  <i class="fas fa-phone-volume"></i>
                  <div class="contact-title_icon">
                    <p>Phone</p>
                    <h6>+91 9995909822</h6>
                  </div>
                </div>
                <div class="contact-title">
                  <i class="fas fa-envelope"></i>
                  <div class="contact-title_icon">
                    <p>Email</p>
                    <h6>thearoorclinic@gmail.co</h6>
                  </div>
                </div>
                <hr />
              </div>
              <div class="contact-box">
                <div class="contact-title">
                  <h4>Location</h4>
                  <i class="fas fa-map-marker-alt"></i>
                  <div class="contact-title_icon">
                    <p>Address</p>
                    <h6>
                      Kannikatt Shopping Complex <br />
                      Aroor gram panchayat <br />
                      Kochi,Kerala, 688534
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="get-in-touch get-in-touch-style2">
                <img
                  src="images/contact-form-bg.png"
                  class="img-fluid get-in-bg"
                  alt="#"
                />
                <h3>Get in Touch with Us</h3>
                <form
                  action="https://api.web3forms.com/submit"
                  method="POST"
                  id="form"
                >
                  <input
                    type="hidden"
                    name="apikey"
                    value="YOUR_ACCESS_KEY_HERE"
                  />
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          name="first name"
                          placeholder="First Name"
                          required
                        />
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          name="last name"
                          placeholder="Last Name"
                          required
                        />
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          name="email address"
                          placeholder="Your Email"
                          required
                        />
                        <i class="far fa-envelope"></i>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="number"
                          class="form-control"
                          name="phone number"
                          placeholder="Your Phone Number"
                          required
                        />
                        <i class="fas fa-phone"></i>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group textarea-icon">
                        <textarea
                          class="form-control"
                          name="message"
                          required
                          placeholder="Your Message"
                          id=""
                          rows="3"
                        ></textarea>
                        <i class="far fa-envelope"></i>
                        <button type="submit" class="btn btn-success">
                          BOOK NOW
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="result" class="text-white"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="map">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <iframe
                width="100%"
                height="450"
                id="gmap_canvas"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3930.6650195921734!2d76.3034024!3d9.8784453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0871c24a16cedf%3A0x66092f4b3f2ecc52!2sThe%20Aroor%20Clinic!5e0!3m2!1sen!2sin!4v1673435794259!5m2!1sen!2sin"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
