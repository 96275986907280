import React from "react";
import Contact from "../Components/contactpage/ContactPage";

function GalleryPage() {
  return (
    <div>
      <Contact />
    </div>
  );
}

export default GalleryPage;
