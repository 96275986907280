import React from "react";
import AddIcon from "@mui/icons-material/Add";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";
function SubHeader() {
  const navigate = useNavigate();
  return (
    <div>
      <section>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-bar">
                <div class="row">
                  <div class="col-lg-3 col-md-12">
                    <a class="navbar-brand"  onClick={()=>{navigate('/')}}>
                      <img src="images/logo-2.png" alt="#" />
                    </a>
                  </div>
                  <div class="col-md-9 d-flex align-items-end">
                    <ul class="ml-auto">
                      <li>
                        <img src="images/mail-icon.png" alt="#" />
                        <div>
                          <span>Mail us</span>
                          <h4>thearoorclinic@gmail.com</h4>
                        </div>
                      </li>
                      <li>
                        <img src="images/call-icon.png" alt="#" />
                        <div>
                          <span>Call Us Now</span>
                          <h4>+91 9995909822</h4>
                        </div>
                      </li>
                      <li class="appointment-btn">
                        <a class="btn btn-primary">Make Appointment</a>
                      
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="light nav-big">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav class="navbar navbar-expand-lg navbar-light">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul class="navbar-nav mr-auto nav-sub">
                    <li class="nav-item ">
                      <a
                        class="nav-link "
                        href="/"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Home
                      </a>
                    </li>
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        id="navbarDropdown1"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        Department{" "}
                        <AddIcon sx={{ fontSize: "small" }}></AddIcon>
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                         <a
                            class="dropdown-item"
                            style={{cursor:"pointer"}}
                            onClick={() => navigate("/General-practitioner")}
                          >
                            General Practitioner
                          </a>
                          <a
                            class="dropdown-item"
                            style={{cursor:"pointer"}}
                            onClick={() => navigate("/Paediatrics")}
                          >
                           Paediatrics
                          </a>
                      </div>
                    </li>

                    <li class="nav-item ">
                      <a
                        class="nav-link "
                        style={{cursor:"pointer"}}
                        onClick={() => navigate("/gallery")}
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Gallery
                      </a>
                    </li>
                    <li class="nav-item ">
                      <a
                        class="nav-link"
                        onClick={() => navigate("/blog")}
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        Blog{" "}
                      </a>
                    </li>

                    <li class="nav-item ">
                      <a
                        class="nav-link"
                        onClick={() => navigate("/about")}
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        About{" "}
                      </a>
                    </li>
                    <li class="nav-item ">
                      <a
                        class="nav-link "
                        onClick={() => navigate("/contact")}
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                  <ul class="nav-icon-wrap">
                    <li>
                      <a >
                        <FacebookIcon sx={{ fontSize: "small" }}></FacebookIcon>
                      </a>
                    </li>
                    <li>
                      <a >
                        <TwitterIcon sx={{ fontSize: "small" }}></TwitterIcon>
                      </a>
                    </li>
                    <li>
                      <a>
                        <InstagramIcon
                          sx={{ fontSize: "small" }}
                        ></InstagramIcon>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <GoogleIcon sx={{ fontSize: "small" }}></GoogleIcon>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
