import React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate=useNavigate()
  return (
    <div>
      <footer>
        <div class="container container-custom">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="foot-contact-block">
                <img src="images/logo-2.png" alt="#" />
                <p style={{ textAlign: "justify" }}>
                  Thank you for choosing our clinic. We look forward to serving
                  you and helping you maintain optimal health. Please don't
                  hesitate to contact us with any questions or to schedule an
                  appointment.
                </p>
                <a href="+91 9995909822">
                  <h4>
                    <i class="fas fa-phone"></i>+91 9995909822<br></br>
                  </h4>
                </a>
                <a href="mailto:info@mededin.com">
                  <h4>
                    <i class="far fa-envelope"></i>thearoorclinic@gmail.com
                  </h4>
                </a>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-2 offset-lg-1">
              <div class="foot-link-box">
                <h4><a  onClick={(e)=>{navigate("/admin")}} style={{textDecoration:"none",cursor:"pointer"}}>Quick Links</a></h4>
                <ul>
                  <li>
                    <a style={{textDecoration:"none",cursor:"pointer"}} onClick={(e)=>{navigate("/about")}}>
                      <i class="fas fa-angle-double-right"></i>About Us
                    </a>
                  </li>
                  {/* <li>
                                <a href="#"><i class="fas fa-angle-double-right"></i>Our Mission</a>
                            </li> */}
                  <li>
                    <a style={{textDecoration:"none",cursor:"pointer"}} onClick={(e)=>{navigate("/gallery")}} >
                      <i class="fas fa-angle-double-right"></i>Gallery
                    </a>
                  </li>
                  <li>
                    <a style={{textDecoration:"none",cursor:"pointer"}} onClick={(e)=>{navigate("/blog")}}  >
                      <i class="fas fa-angle-double-right"></i>Blogs & News
                    </a>
                  </li>
                  <li>
                    <a style={{textDecoration:"none",cursor:"pointer"}} onClick={(e)=>{navigate("/contact")}}>
                      <i class="fas fa-angle-double-right"></i>Contact Us
                    </a>
                  </li>
                  {/* <li>
                                <a href="#"><i class="fas fa-angle-double-right"></i>Faq</a>
                            </li> */}
                </ul>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-2">
              <div class="foot-link-box">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <a href="/">
                      <i class="fas fa-angle-double-right"></i>General
                      Practitioner
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i class="fas fa-angle-double-right"></i> Paediatrics
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-4 col-lg-2 offset-lg-1">
              <div class="foot-link-box footlink-box_btn">
                <h4>Working Hours</h4>
                <ul>
                  <li>
                    <a href="/">
                      <i class="fas fa-angle-double-right"></i>Paediatrics -
                      8.00am to 11.00am
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i class="fas fa-angle-double-right"></i>General
                      Practitioner - 4.30pm to 9.00pm
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="copyright">
                <p>© The Aroor Clinic 2023 Allright Reserved</p>
                <a href="#" id="scroll">
                  <i class="fas fa-angle-double-up"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
